import React from 'react';
import { Section } from './section';
import { Building2, Car, Rocket } from 'lucide-react';

export default function History() {
    let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

    return (
        <div id='about-history' className='min-h-screen px-[6vw] py-6'>
            <div className="mb-12 reveal">
                <h1 className="mb-8 text-[1.5rem] cinco:text-[2rem] text-left font-GilroyBold">
                    {pt ? "Uma Jornada de Inovação e Transformação Digital" : "A Journey of Innovation and Digital Transformation"}
                </h1>
            </div>

            <div className='flex flex-col-reverse w-full gap-10 mil:flex-row'>
                <Section title="">
                    <p className='reveal text-[1.2rem] cinco:text-[1rem]'>
                        {pt ?
                            "Fundada oficialmente em meados de 2023, a Vision D é o resultado de um percurso marcado por ambição e inovação tecnológica. O projeto começou em novembro de 2022 como Spody, uma ideia focada na educação." :
                            "Officially founded in mid-2023, Vision D is the result of a journey marked by ambition and technological innovation. The project began in November 2022 as Spody, an idea focused on education."
                        }
                    </p>
                    <p className='reveal text-[1.2rem] cinco:text-[1rem]'>
                        {pt ?
                            "Com o amadurecimento da equipa, a visão expandiu-se para abarcar soluções digitais mais amplas, deixando a ideia inicial para trás. Assim nasceu a Vision D, uma startup de serviços informáticos dedicada a transformar desafios tecnológicos em oportunidades de crescimento." :
                            "As the team matured, the vision expanded to encompass broader digital solutions, leaving the initial idea behind. Thus was born Vision D, a computer services startup dedicated to transforming technological challenges into growth opportunities."
                        }
                    </p>
                </Section>
                <div className="relative w-full mb-8 mil:w-1/2 aspect-video">
                    <div className='absolute w-full h-full bg-white rounded-xl -bottom-4 -left-4'></div>
                    <div className='relative w-full h-full'>
                        <div className='absolute top-0 left-0 w-full h-full bg-red'></div>
                        <iframe
                            src="https://www.youtube.com/embed/ZR9BLidZqNU?si=tQjh8l6QJp9q2Uld"
                            title="Vision D Video"
                            allow="autoplay; encrypted-media"
                            className='relative w-full h-full rounded-xl'
                        />
                    </div>
                </div>
            </div>

            <Section title={pt ? "Setores Específicos" : "Specific Sectors"}>
                <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
                    <div className="flex flex-col items-center p-6 text-center bg-white rounded-lg shadow-md my-card">
                        <Building2 className="w-12 h-12 mb-4 text-green" />
                        <h3 className="mb-2 font-bold">{pt ? "ERPs e Automações" : "ERPs and Automations"}</h3>
                        <p className='text-[1rem]'>
                            {pt ?
                                "Sistemas de gestão e plataformas para experiência entre a empresa e o cliente" :
                                "Management systems and platforms for the company-client experience"
                            }
                        </p>
                    </div>
                    <div className="flex flex-col items-center p-6 text-center bg-white rounded-lg shadow-md my-card">
                        <Rocket className="w-12 h-12 mb-4 text-green" />
                        <h3 className="mb-2 font-bold">{pt ? "Startups" : "Startups"}</h3>
                        <p className='text-[1rem]'>
                            {pt ?
                                "Software online personalizado, integrações e outros projetos inovadores como a Worklab" :
                                "Custom online software, integrations, and other innovative projects like Worklab"
                            }
                        </p>
                    </div>
                    <div className="flex flex-col items-center p-6 text-center bg-white rounded-lg shadow-md my-card">
                        <Car className="w-12 h-12 mb-4 text-green" />
                        <h3 className="mb-2 font-bold">{pt ? "Mobilidade" : "Mobility"}</h3>
                        <p className='text-[1rem]'>
                            {pt ?
                                "Projetos Multigroup que melhoram a mobilidade das cidades" :
                                "Multigroup projects improving urban mobility"
                            }
                        </p>
                    </div>
                </div>
            </Section>
        </div>
    );
}
