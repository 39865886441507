import React, { useState, useEffect, useRef } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import { MdArrowBackIosNew } from "react-icons/md";

import "./sectionAbout.css";
import About from "./about";
import Create from "./create";
import Code from "./code";
import Launch from "./launch";

function SectionAbout() {
  const [section, setSection] = useState(0);
  const [selectedSection, setSelectedSection] = useState(-1);
  const totalSections = 4;
  const components = [<About />, <Create />, <Code />, <Launch />];
  const buttonLabels = ["about", "create", "code", "launch"];
  const isPortuguese = localStorage.getItem("lang") === "pt";

  const sectionRef = useRef<HTMLDivElement>(null);

  // Automatic section transition
  useEffect(() => {
    if (selectedSection !== -1) return;

    const interval = setInterval(
      () => {
        setSection((prevSection) => (prevSection + 1) % totalSections);
      },
      section === 0 ? 12000 : 3000
    );

    return () => clearInterval(interval);
  }, [section, selectedSection]);

  // Fade-in effect when section changes
  useEffect(() => {
    if (sectionRef.current) {
      sectionRef.current.classList.remove("fade-in");
      // Trigger reflow to restart the animation
      void sectionRef.current.offsetWidth;
      sectionRef.current.classList.add("fade-in");
    }
  }, [section]);

  // Additional effect for the 'About' section
  useEffect(() => {
    if (section === 0) {
      const reveals = document.querySelectorAll("#home-about-left .reveal");
      for (let i = 0; i < reveals.length; i++) {
        const windowHeight = window.innerHeight;
        const revealTop = reveals[i].getBoundingClientRect().top;
        const revealPoint = 150;

        if (revealTop < windowHeight - revealPoint) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    }
  }, [section]);

  // Navigation functions
  const btnLeftFunction = () => {
    setSection((prevSection) =>
      prevSection === 0 ? totalSections - 1 : prevSection - 1
    );
    setSelectedSection(-1);
  };

  const btnRightFunction = () => {
    setSection((prevSection) => (prevSection + 1) % totalSections);
    setSelectedSection(-1);
  };

  const setSectionFunction = (sectionNumber: number) => {
    setSection(sectionNumber);
    if (selectedSection === 0) setSelectedSection(sectionNumber);
  };

  // Handle focus and blur on buttons
  const handleFocus = (sectionNumber: number) => {
    if (selectedSection === 0) setSelectedSection(sectionNumber);
  };

  const handleBlur = () => {
    setSelectedSection(-1);
  };

  return (
    <section
      id="home-sectionAbout"
      className="relative flex justify-center w-full min-h-[80vh] cinco:min-h-[90vh] top-20"
    >
      <div
        ref={sectionRef}
        className="rounded-[25px] absolute w-full quatro:w-[90%] miletres:w-[80%] h-full"
      >
        {components[section]}
        <div className="absolute bottom-0 left-0 z-40 flex flex-col justify-between w-full gap-8 pl-10 pr-10 nove:items-end nove:flex-row reveal pb-14">
          <div className="text-left text-h5 sete:text-h3">
            <p>{isPortuguese ? "A sua visão," : "Developing"}</p>
            <p>
              {isPortuguese ? "a nossa implementação." : "your Digital Dream."}
            </p>
          </div>
          <div className="flex items-center justify-center nove:items-end nove:justify-end text-p">
            <div className="flex items-center gap-6">
              <MdArrowBackIosNew
                onClick={btnLeftFunction}
                className="cursor-pointer"
              />
              <div className="relative flex justify-center gap-3 cinco:gap-6">
                {buttonLabels.map((label, index) => (
                    <button
                    key={index}
                    onClick={() => setSectionFunction(index)}
                    className={`flex justify-around items-center text-center w-[3rem] cinco:w-[4rem] milecem:w-[6rem] h-[3rem] cinco:h-[4rem] milecem:h-[6rem] border-2 border-white rounded-[10px] cinco:rounded-[15px] milecem:rounded-[20px] text-[0.8rem] transition-all duration-200 ${
                      section === index ? "scale-125 bg-white text-black" : ""
                    } `}
                    onFocus={() => handleFocus(index)}
                    onBlur={handleBlur}
                    >
                      {label}
                    </button>
                ))}
              </div>
              <MdArrowForwardIos
                onClick={btnRightFunction}
                className="cursor-pointer"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionAbout;
