import React from "react";

import CodeIcon from "../../../content/images/icons-logos/logo-code.png";
// import logoVisiond from "../../../content/images/icons-logos/logo-visiond.png";
import logoAPCH from "../../../content/images/icons-logos/apch.png";

function reveal() {
  var reveals = document.querySelectorAll('.reveal');

  for (var i = 0; i < reveals.length; i++){
      var windowHeight = window.innerHeight;
      var revealTop = reveals[i].getBoundingClientRect().top;
      var revealPoint = 150;

      if (revealTop < windowHeight - revealPoint) {
          reveals[i].classList.add('active');
      }
      else {
          reveals[i].classList.remove('active');
      }
  }  
}

function goToForm() {
  localStorage.setItem("form", "Web");
  window.location.replace('/form');
}

window.addEventListener('scroll', reveal);

function Code() {
  let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
  return (
    <section id="services-code" className="relative flex h-screen bg-center bg-no-repeat bg-cover service-all bg-servicesWeb">
      <div className="absolute w-full h-full fade-black-img"></div>
      <div className="reveal">
        <div className="relative flex h-full p-24 services-container">
          <div className="relative flex items-center justify-start w-1/2 h-full services-container-left">
            <div className="relative flex flex-col justify-around h-full services-container-line mt-14">
              <div className="relative services-container-line-point code bigger "></div>
              <div className="relative services-container-line-point app "></div>
              <div className="relative services-container-line-point ux "></div>
              <div className="relative services-container-line-point web3 "></div>
              <div className="relative services-container-line-point ai "></div>
              <div className="relative services-container-line-point loaning"></div>
            </div>
            <div className="relative flex flex-col items-start justify-start h-full services-container-info p-14">
              <div className="services-container-info-img">
                <img src={CodeIcon} alt="startup" />
              </div>
              <div className="mt-6 text-left services-container-info-title">
                <h1 className="text-h3">{pt ? "Desenvolvimento" : "Web"}</h1>
                <h1 className="text-h3">{pt ? "web" : "development"}</h1>
              </div>
              <div className="mt-6 text-left services-container-info-text">
              {pt ?
              <p>
                Cativa a tua audiência e impulsiona o desempenho do teu negócio com websites ou plataformas web criadas pela nossa equipa especializada. <br /><br /> A nossa tecnologia e software entregam resultados tangíveis que te diferenciam da concorrência.  
              </p>
              :              
              <p>
                Captivate your audience and boost business performance with websites or web apps created by our expert developers. <br /><br /> Our cutting-edge technology and user-friendly software deliver tangible results that set you apart from the competition.  
              </p>}
              </div>
            </div>
          </div>
          <div className="relative flex-col items-start justify-end hidden w-1/2 h-full pb-40 cinco:flex services-container-right">
              <div className="mb-10 services-container-portofolio-title">
                <h1 className="relative text-left text-white text-h3">{pt ? "Portfolio" : "Portfólio"}</h1>
              </div>
              <div className="relative flex seis:max-w-[25rem] max-w-[18rem] overflow-x-auto my-scrollbar h">
                <div className="relative flex justify-start mb-2 services-container-portofolio-cards-container">
                  {/* <div className="relative flex items-center justify-center bg-cover cursor-pointer services-container-portofolio-card bg-homeHeader hover:">
                    <img src={logoVisiond} alt="logo" className="relative w-32"/>
                  </div> */}
                  <div onClick={() => window.location.replace('https://wedrone.pt')} className="relative flex items-center justify-center bg-center bg-cover cursor-pointer services-container-portofolio-card wedrone bg-WedroneBg hover:">
                  </div>
                  <div onClick={() => window.location.replace('https://casashistoricas.org')} className="relative flex items-center justify-center bg-white bg-center bg-cover cursor-pointer services-container-portofolio-card hover:">
                    <img src={logoAPCH} alt="logo" className="relative w-16"/>
                  </div>
                  <div onClick={() => window.location.replace('https://worklabspace.com')} className="relative flex items-center justify-center bg-center bg-cover cursor-pointer services-container-portofolio-card bg-worklab hover:">
                  </div>
                  <div onClick={() => window.location.replace('https://montedahortinha.com')} className="relative flex items-center justify-center bg-center bg-cover cursor-pointer services-container-portofolio-card bg-hortinha hover:">
                  </div>
                </div>
              </div>
          </div>
          <div onClick={() => goToForm()} className="absolute service-contact-btn bottom-16 right-16"><span className="mr-2">{pt ? "interessado" : "interested"}</span><span>→</span></div>
        </div>
      </div>
    </section>
  );
}


export default Code;