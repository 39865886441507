import React from 'react';
import Header from './components/header';
import History from './components/history';
import Techs from './components/techs';
import Team from './components/team';

export default function About() {

    return (
        <div className='bg-gradient-to-b from-[transparent] to-[#262222]'>
            <Header /> 
            <Team />
            <History /> 
            <Techs /> 
        </div>
    );
}

