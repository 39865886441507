import React from "react";
import "./about.css";

function reveal() {
  var reveals = document.querySelectorAll('.reveal');

  for (var i = 0; i < reveals.length; i++){
      var windowHeight = window.innerHeight;
      var revealTop = reveals[i].getBoundingClientRect().top;
      var revealPoint = 150;

      if (revealTop < windowHeight - revealPoint) {
          reveals[i].classList.add('active');
      }
      else {
          reveals[i].classList.remove('active');
      }
  }  
}

window.addEventListener('scroll', reveal);

let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt";

function About() {
  return (
    <div  className="relative flex items-start justify-start w-3/5 bg-[70%_0%] bg-no-repeat bg-cover home-section1-sections bg-homeAbout">
      <div className="absolute top-0 left-0 h-full w-full rounded-[25px] bg-[rgba(0,0,0,.6)] cinco:bg-[rgba(0,0,0,.3)]"></div>
      <div id="home-about-left" className="relative flex flex-col items-start justify-start w-full pl-0 overflow-hidden text-left seis:w-1/2 milecem:pl-14 pt-14">
          <h2 id="home-about-title" className="mb-10 font-bold reveal text-h2 ">{pt ? "Sobre nós" : "About us"}</h2>
          <h3 className="w-full reveal">
            {pt ? 
              <>
                <p className="seis:w-full w-[15rem]">A Vision D oferece serviços digitais personalizados com foco nas necessidades exclusivas de cada cliente.</p>
                <p className="seis:w-full w-[15rem]">Nosso painel de controle baseado em IA auxilia na consultoria estratégica, ajudando empresas a inovar e crescer com confiança.</p>
                <p className="seis:w-full w-[15rem]">Apoiamos startups em ideias inovadoras e também lançamos soluções próprias, como o Worklab – um dashboard de talentos em TI com um construtor de projetos inteligente integrado.</p>
              </>
              :
              <>
                <p className="seis:w-full w-[15rem]">Vision D provides personalized digital services tailored to meet each client’s unique needs.</p>
                <p className="seis:w-full w-[15rem]">Our AI-powered dashboard supports strategic consulting, helping businesses innovate and grow confidently.</p>
                <p className="seis:w-full w-[15rem]">We also venture into startups with groundbreaking ideas and launch our own solutions, like Worklab – an IT talent dashboard with an integrated AI project builder.</p>
              </>
            }
          </h3>
      </div>
    </div>
  );
}

export default About;
