import React from 'react';
import { useState } from "react";
import './navbar.css';
import { IoIosArrowUp, IoIosArrowDown  } from "react-icons/io";
import logovision from "../../content/images/icons-logos/logo-visiond.png";

function CustomLink( { ...props } ) {
  const path = window.location.pathname;
  let className = "link";
  if (path === props.href || (props.href === "/budget" && path === "/form")) {
    className = "link active";
  }
  return (
    <li>
      <a href={props.href} className={className}>
        {props.children}
      </a>
    </li>
  )
}

function openMenu() {
  let navbarLinks = document.getElementById("navbar");
  let navbarIcon = document.getElementById("menu-icon");

  if (navbarLinks?.classList.contains("open")) {
    navbarLinks?.classList.remove("open");
    navbarIcon?.classList.remove("closed");
  } else {
    navbarLinks?.classList.add("open");
    navbarIcon?.classList.add("closed");
  }
}

function Navbar() {
  const [navbar, setNavbar] = useState(false);
  const [clicked, setClicked] = useState('');

  var lastScrollTop = window.scrollY;
  var navbarElem = document.getElementById("navbar");

  function MultipleLink( { ...props } ) {
    const path = window.location.pathname;
    let className = "flex items-center w-full gap-2 link";
    if (path === props.href || (props.href === "/budget" && path === "/form")) {
      className = className + " active";
    }
    return (
      <li className={`flex flex-col items-center`}>
        <button 
          onClick={clicked === props.children 
          ? () => {window.innerWidth > 900 && setNavbar(navbar && window.scrollY >= 80 ? navbar : false); setClicked('')} 
          : () => {window.innerWidth > 900 && setNavbar(true); setClicked(props.children)}} 
          className={className}
        >
          <p>{props.children}</p>
          <p className='hidden nove:flex'>{clicked === props.children ? <IoIosArrowUp /> : <IoIosArrowDown />}</p>
        </button>
        <div className={`relative nove:absolute nove:left-0 flex-col w-full novept-6 nove:pb-16 nove:top-full ${clicked === props.children ? 'flex' : 'hidden'} hover:flex nove:bg-[var(--navbar)] nove:px-[8vw] text-left`}>
          <p className='text-[2rem] font-bold nove:flex hidden'>{props.children}</p>
          <div className='flex flex-col gap-2 transition-all duration-300 border-l-2 border-[var(--purple)] nove:border-none nove:gap-6 nove:mt-8 nove:flex-row'>
            {props.links.map((link:any, index:number) => (
              <a key={index} href={link} className='nove:p-6 nove:border-2 nove:border-white hover:border-[var(--purple)] cursor-pointer rounded-[15px] transition-all duration-300 flex gap-2 items-center max-w-[17rem] hover:seis:max-w-[25rem] max-w-[18rem]'>
                <p className='text-left text-[1.2rem] px-4'>{props.texts[index]}</p>
              </a>
            ))}
          </div>
        </div>
      </li>
    )
  }

  const changeBackground = () => {
    if (navbarElem?.classList.contains("open")) {
      navbarElem?.classList.remove("open");
    }
    if (window.scrollY < lastScrollTop || window.scrollY < 500) {
      navbarElem?.classList.remove("navbar-disappear");
    } else {
      navbarElem?.classList.add("navbar-disappear");
    }
    lastScrollTop = window.scrollY;
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
    setClicked('');
  }

  function changeLanguage(language:string) {
    document.getElementById("portuguese")?.classList.remove("active");
    document.getElementById("english")?.classList.remove("active");
    if (language === "pt") {
      document.getElementById("portuguese")?.classList.add("active");
      localStorage.setItem('lang', 'pt');
    } else {
      document.getElementById("english")?.classList.add("active");
      localStorage.removeItem('lang');
    }
    window.location.reload();
  }


  window.addEventListener('scroll', changeBackground);

  let navbarClass = navbar ? "active " : "";
  navbarClass = navbarClass + " fixed flex justify-end text-blue z-50";

  let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

  return (
      <nav id="navbar" className={navbarClass}>
        <div id="navbar-menu" className="relative flex items-center justify-end w-1/2 h-full">
          <div id="navbar-logo" className="absolute left-0 flex items-center pl-20">
            <img onClick={() => window.location.replace('/')} src={logovision} alt="Logo vision" className="w-[9.5rem] hover: cursor-pointer" />
          </div>
          <div id="navbar-menu-icon" className="relative flex items-start justify-end hidden w-1/2">
            <div className="relative flex flex-col items-center justify-center cursor-pointer navbar-menu-icon-btn hover:"  onClick={() => openMenu()}>
              <div className="navbar-menu-icon one"></div>
              <div className="navbar-menu-icon two"></div>
              <div className="navbar-menu-icon three"></div>
            </div> 
          </div>
        </div>
        <ul id="navbar-links" className="flex items-center justify-between w-3/5 pr-20 text-white text-h6">
          <MultipleLink links={["/services", "/portfolio", "/budget"]} texts={pt ? ["Serviços", "Portfólio", "Orçamentos"] : ["Services", "Portfolio", "Budget"]}>{pt ? "Clientes" : "Clients"}</MultipleLink>
          <MultipleLink links={[`/partner?partnerName=${pt ? 'A Sua Empresa' : 'You'}`, "/new-partner"]} texts={pt ? ["Parcerias", "Ser parceiro"] : ["Partnerships", "Become a partner"]}>{pt ? "Parceiros" : "Partners"}</MultipleLink>
          <MultipleLink links={["/about", "/apply", "/blog"]} texts={pt ? ["Sobre nós", "Candidatar", "Blog"] : ["About us", "Apply", "Blog"]}>{pt ? "Sobre" : "About"}</MultipleLink>
          <CustomLink href="/contact">{pt ? "Contactos" : "Contact"}</CustomLink>
          <li id='multi-lang' className="relative flex p-[7px] ">
            {pt ? <span onClick={() => changeLanguage("pt")} id="portuguese" className="cursor-pointer active"><b>PT</b>&ensp;</span> : <span onClick={() => changeLanguage("pt")} id="portuguese" className="cursor-pointer">PT&ensp;</span>}
            <span>|</span>
            {pt ? <span onClick={() => changeLanguage("en")} id="english" className="cursor-pointer">&ensp;EN</span> : <span onClick={() => changeLanguage("en")} id="english" className="cursor-pointer active">&ensp;<b>EN</b></span>}
          </li>
        </ul>
      </nav>
  );
}

export default Navbar;