"use client";

import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Zap,
  Lightbulb,
  Puzzle,
  Rocket,
  ChevronDown,
} from "lucide-react";
import BtnVideo from "../sectionServices/services/btnVideo";

import Solution1 from "../../../content/images/home/cards/worklab.png";
import Solution2 from "../../../content/images/home/cards/multipark.png";
import Solution3 from "../../../content/images/home/cards/modules.png";

import Consulting1 from "../../../content/images/home/cards/oppDash.png";

import PersonalizedProjects from "../../../content/images/home/cards/tl-tools.png";
import PersonalizedProjects2 from "../../../content/images/home/cards/wln2.png";
import PersonalizedProjects3 from "../../../content/images/home/cards/multipark2.png";

import Startup1 from "../../../content/images/home/cards/wln.png";
import Startup2 from "../../../content/images/home/cards/spody.png";

import "./sectionAreas.css";

export default function ExpandableDigitalCompanySection() {
  const pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

  const [expandedCard, setExpandedCard] = useState<number | null>(null);
  const [cards, setCards] = useState<any>([]);
  const [currentImageIndex, setCurrentImageIndex] = useState<number[]>([]);

  useEffect(() => {
    setCards([
      {
        title: pt ? "Soluções" : "Solutions",
        description: pt
          ? "Ferramentas inovadoras que impulsionam o sucesso"
          : "Innovative tools that drive your success",
        about: pt
          ? "Descubra as nossas soluções modernas que podem ajudar a simplificar o seu dia a dia. Desde dashboards com IA até soluções de software personalizadas, temos as ferramentas de que precisa para ter sucesso. Alguns dos projetos mais recentes incluem Multipark, Opportunity Dashboard e Worklab."
          : "Discover our range of modern solutions that can help streamline your daily life. From ai_powered dashboards, to custom software solutions, we have the tools you need to succeed. Some of the most recent launched projects include Multipark, Opportunity Dashboard, and Worklab.",
        images: [Solution1, Solution2, Solution3],
        url: "#dashboard-all",
        icon: <Zap className="w-8 h-8 text-[#60a5fa]" />,
      },
      {
        title: pt ? "Consultoria" : "Consulting",
        description: pt
          ? "Orientação especializada para transformar o negócio"
          : "Expert guidance to transform your business",
        about: pt
          ? "Os nossos serviços de consultoria são concebidos para ajudá-lo a navegar pelas complexidades do mundo digital moderno. Apresentamos-lhe o Opportunity Dashboard, uma plataforma com IA que fornece insights e análises para ajudá-lo a tomar decisões informadas e candidatar-se às melhores oportunidades."
          : "Our consulting services are designed to help you navigate the complexities of the modern digital world. We bring you the Opportunity Dashboard, an ai_powered platform that provides insights and analytics to help you make informed decisions and apply to the best opportunities.",
        images: [Consulting1],
        url: "https://opportunity.visiond.pt",
        icon: <Lightbulb className="w-8 h-8 text-[#60a5fa]" />,
      },
      {
        title: pt ? "Projetos Personalizados" : "Personalized Projects",
        description: pt
          ? "Abordagens personalizadas para ideias e desafios únicos"
          : "Tailored approaches for your unique challenges",
        about: pt
          ? "Entendemos que cada negócio é único e trabalhamos consigo para desenvolver soluções que atendam às suas necessidades específicas. A nossa equipa de especialistas irá colaborar consigo para criar uma solução personalizada que o ajudará a alcançar os seus objetivos."
          : "We understand that every business is unique, and we work with you to develop solutions that meet your specific needs. Our team of experts will work with you to develop a personalized solution that will help you achieve your goals.",
        images: [PersonalizedProjects, PersonalizedProjects2, PersonalizedProjects3],
        url: "#home-SectionServices",
        icon: <Puzzle className="w-8 h-8 text-[#60a5fa]" />,
      },
      {
        title: pt ? "Startups" : "Startup Venture",
        description: pt
          ? "Ajudar a próxima geração de inovadores"
          : "Empowering the next generation of innovators",
        about: pt
          ? "O programa de startups da Vision D foi desenhado para ajudá-lo a transformar as suas ideias em realidade. Oferecemos os recursos, ferramentas e expertise de que necessita para ter sucesso. Junte-se a nós e deixe-nos ajudá-lo a realizar a sua visão."
          : "Vision D's startup program is designed to help you bring your ideas to life. We provide you with the resources, tools, and expertise you need to succeed. Join us and let us help you turn your vision into reality.",
        images: [Startup1, Startup2],
        url: "https://startups.visiond.pt",
        icon: <Rocket className="w-8 h-8 text-[#60a5fa]" />,
      },
    ]);

    setCurrentImageIndex([0, 0, 0, 0]);
  }, [pt]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndexes) =>
        prevIndexes.map((currentIndex, i) => {
          const cardImages = cards[i].images;
          return (currentIndex + 1) % cardImages.length;
        })
      );
    }, 4000);

    return () => clearInterval(interval);
  }, [cards]);

  const handleCardInteraction = (index: number) => {
    setExpandedCard(expandedCard === index ? null : index);
  };

  if (!cards.length) return null;

  return (
    <section className="px-4 py-20 text-white md:px-6 bg-gradient-to-br from-gray-900 via-black to-gray-800">
      <div className="max-w-6xl mx-auto">
        <h2 className="mb-4 text-center text-transparent text-h3 cinco:text-h2 font-GilroyBold reveal my-text">
          {
            pt
              ? "A criar o Futuro da Inovação Digital"
              : "Shaping the Future of Digital Innovation"
          }
        </h2>
        <p className="mb-12 text-center text-[#c1c1c1] cinco:text-h5 text-balance reveal">
          {
            pt
              ? "Descubra soluçõe que impulsionam negócios para o futuro. Desde análises com IA até software personalizado, somos o parceiro ideal na transformação digital."
              : "Discover cutting-edge solutions that propel your business into the future. From AI-powered analytics to custom software, we're your partner in digital transformation."
          }
        </p>

        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 reveal">
          {cards.map((card: any, index: number) => (
            <motion.div
              key={card.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className={`flex flex-col gap-4 p-6 bg-gray-800 rounded-lg shadow-md cursor-pointer my-card group h-full transition-all ${
                expandedCard === index ? "grow" : "max-h-[140px]"
              }`}
              onClick={() => handleCardInteraction(index)}
            >
              <div className="flex items-center justify-between">
                <div
                  className={expandedCard === index ? "animate-spin" : ""}
                  style={{ animationIterationCount: "1" }}
                >
                  {card.icon}
                </div>
                <h3 className="cinco:text-[2rem] text-[1.3rem] text-white font-GilroyBold">
                  {card.title}
                </h3>
                <ChevronDown
                  className={`h-6 w-6 transition-transform ${
                    expandedCard === index ? "rotate-180" : ""
                  }`}
                />
              </div>
              <p className="text-left text-[#c1c1c1]">{card.description}</p>
              <AnimatePresence>
                {expandedCard === index && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                    className="flex flex-col gap-4 mt-4"
                  >
                    <p className="text-[#c1c1c1] text-left">{card.about}</p>
                    <div className="relative w-full overflow-hidden rounded-[10px] min-h-[17rem] max-h-[22rem] border-[#c1c1c1] border-[1px]">
                      <img
                        src={card.images[currentImageIndex[index]]}
                        alt={`${card.title}`}
                        className="absolute object-contain w-full h-full transition-opacity duration-1000 rounded-[10px]"
                      />
                    </div>
                    <div
                      className={`flex justify-center w-full ${
                        expandedCard === index ? "opacity-100" : "opacity-0"
                      }`}
                    >
                      <a
                        href={card.url}
                        className="relative flex items-center justify-center w-48 h-12 overflow-hidden text-white bg-center bg-no-repeat bg-cover border rounded-full"
                      >
                        <div className="absolute flex w-full h-full section2-service-video code">
                          <BtnVideo />
                        </div>
                        <p className="z-50">
                          {pt ? "saber mais" : "learn more"} &ensp; →
                        </p>
                      </a>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </div>

        <div className="mt-16 text-center">
          <h3 className="mb-4 text-[2rem] font-bold text-blue-400">
            {
              pt
                ? "Quer saber mais?"
                : "Want to know more?"
            }
          </h3>
          <button
            className="px-6 py-3 text-white rounded-lg bg-[#60a5fa] hover:bg-[#499bff]"
            onClick={() => window.location.href = "/about"}
          >
            {pt ? "Sobre a Vision D" : "About Vision D"} &ensp; →
          </button>
        </div>
      </div>
    </section>
  );
}
