import React, { useEffect, useState, useRef } from "react";

interface StatItemProps {
  value: number; // valor final numérico
  label: string;
}

function useInView(threshold: number = 0.1): [React.RefObject<HTMLDivElement>, boolean] {
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [threshold]);

  return [ref, isVisible];
}

function useAnimatedNumber(target: number, duration: number = 2000, trigger: boolean): number {
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    if (!trigger) return; // Só inicia se estiver visível

    let start = 0;
    const stepTime = 1000 / 60; // 60 FPS
    const steps = Math.ceil(duration / stepTime);
    const increment = target / steps;

    let frame = 0;

    function updateCounter() {
      frame++;
      const nextValue = Math.min(target, start + increment * frame);
      setCurrentValue(nextValue);

      if (nextValue < target) {
        requestAnimationFrame(updateCounter);
      }
    }

    requestAnimationFrame(updateCounter);

    return () => {
      setCurrentValue(0); // Reset ao desmontar
    };
  }, [trigger, target, duration]);

  return currentValue;
}

function StatItem({ value, label }: StatItemProps) {
  const [ref, isVisible] = useInView(0.3); // 30% da seção visível
  const animatedValue = useAnimatedNumber(value, 1000, isVisible); // Animação só ocorre se estiver visível

  return (
    <div ref={ref} className="text-center">
      <div className="mb-1 text-[2rem] font-bold text-blue-600">
        {Math.floor(animatedValue)}+
      </div>
      <div className="text-sm text-gray-600">{label}</div>
    </div>
  );
}

export function Statistics() {
  let pt = localStorage.getItem("lang") === "pt";

  return (
    <div className="grid grid-cols-2 gap-8 p-4 mt-8 rounded-lg shadow-md cinco:p-8 my-card md:grid-cols-4 reveal">
      <StatItem value={20} label={pt ? "Colaboradores" : "Employees"} />
      <StatItem value={5} label={pt ? "Startups" : "Launched Startups"} />
      <StatItem value={25} label={pt ? "Parcerias" : "Partnerships"} />
      <StatItem value={20} label={pt ? "Clientes" : "Clients"} />
    </div>
  );
}
