import React from 'react';
import { TeamMember } from './teamMember';
import { Statistics } from './stats';

export default function Team() {
  let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

  const teamMembers = [
    {
      name: "Duarte Barreiros",
      role: pt ? "Estratégia e Design" : "Strategy and Design",
      images: ["/partners/photos/teams/db1.png", "/partners/photos/teams/db2.png"],
      bio: pt ? "Diretor de design e estratégia de negócios, com experiência em inovação e criatividade." : "Director of design and business strategy, with experience in innovation and creativity.",
      link: "duartebarreiros"
    },
    {
      name: "Rafael Cóias",
      role: pt ? "Tecnologia e Desenvolvimento" : "Technology and Development",
      images: ["/partners/photos/teams/rc1.png", "/partners/photos/teams/rc2.png"],
      bio: pt ? "Diretor de tecnologia e desenvolvimento, com experiência em programação e soluções digitais." : "Director of technology and development, with experience in programming and digital solutions.",
      link: "rafaelcoias"
    },
    {
      name: "Duarte Sousa",
      role: pt ? "Finanças e Contabilidade" : "Finance and Accounting",
      images: ["/partners/photos/teams/ds1.png", "/partners/photos/teams/ds2.png"],
      bio: pt ? "Diretor de finanças e contabilidade, com experiência em gestão de empresas e investment." : "Director of finance and accounting, with experience in managing companies and investment.",
      link: "duartesousa"
    },
    {
      name: "Tomás Rocha",
      role: pt ? "Marketing e Vendas" : "Marketing and Sales",
      images: ["/partners/photos/teams/tr1.png", "/partners/photos/teams/tr2.png"],
      bio: pt ? "Director de marketing e vendas, com experiência em estratégias de crescimento e comunicação." : "Director of marketing and sales, with experience in growth strategies and communication.",
      link: "tomasrocha"
    },
  ];

  return (
    <section id='about-teams' className="mb-12 px-[6vw] py-10">
      <h2 className="mb-4 text-[2rem] font-GilroyBold">{pt ? "A Nossa Equipa" : "Our Team"}</h2>
      <div className="mb-8 reveal">
        <div className="text-left text-[1.2rem]">
          <p className="mb-4 text-gray-700">
            {
              pt ? "Na Vision D, operamos com uma estrutura ágil e colaborativa, onde cada equipa é\
            responsável por um aspecto crucial do nosso negócio. A nossa metodologia de trabalho\
            baseia-se em:"
                :
                "At Vision D, we operate with an agile and collaborative structure, where each team is\
            responsible for a crucial aspect of our business. Our work methodology is based on:"
            }
          </p>
          <ul className="space-y-2 text-gray-700 list-disc list-inside">
            <li>{pt ? "Metodologia Agile com sprints semanais" : "Agile methodology with weekly sprints"}</li>
            <li>{pt ? "Autonomia e responsabilidade" : "Autonomy and responsibility"}</li>
            <li>{pt ? "Colaboração e comunicação" : "Collaboration and communication"}</li>
            <li>{pt ? "Inovação e criatividade" : "Innovation and creativity"}</li>
            <li>{pt ? "Transparência e confiança" : "Transparency and trust"}</li>
          </ul>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 reveal">
        {teamMembers.map((member) => (
          <TeamMember key={member.name} {...member} />
        ))}
      </div>

      <Statistics />
    </section>
  );
}