import React from 'react';

interface TechItemProps {
  name: string;
  imageUrl: string;
}

function TechItem({ name, imageUrl }: TechItemProps) {
  return (
    <div className='!rounded-[20px] overflow-hidden my-border'>
      <div className="flex flex-col items-center p-4 transition-shadow my-card">
        <img src={imageUrl} alt={name} className="object-contain w-12 h-12 mb-2" />
        <span className="text-sm">{name}</span>
      </div>
    </div>
  );
}

interface TechCategoryProps {
  title: string;
  technologies: any[];
}

export default function TechCategory({ title, technologies }: TechCategoryProps) {
  return (
    <div className="flex flex-col gap-8 py-8 reveal">
      <h3 className="text-[1.5rem] font-bold text-left">{title}</h3>
      <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
        {technologies && technologies?.map((tech: any) => (
          <TechItem key={tech.name} {...tech} />
        ))}
      </div>
    </div>
  );
}