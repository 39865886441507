import React from "react";

import AppIcon from "../../../content/images/icons-logos/logo-app.png";
import SpodyIcon from "../../../content/images/icons-logos/icon-spody.png";

function reveal() {
  var reveals = document.querySelectorAll('.reveal');

  for (var i = 0; i < reveals.length; i++){
      var windowHeight = window.innerHeight;
      var revealTop = reveals[i].getBoundingClientRect().top;
      var revealPoint = 150;

      if (revealTop < windowHeight - revealPoint) {
          reveals[i].classList.add('active');
      }
      else {
          reveals[i].classList.remove('active');
      }
  }  
}

function goToForm() {
  localStorage.setItem("form", "App");
  window.location.replace('/form');
}

window.addEventListener('scroll', reveal);

function App() {
  let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

  return (
    <section id="services-app" className="relative flex h-screen bg-center bg-no-repeat bg-cover service-all bg-servicesApp">
      <div className="reveal">
        <div className="relative flex h-full p-24 services-container">
          <div className="relative flex items-center justify-start w-1/2 h-full services-container-left">
            <div className="relative flex flex-col justify-around h-full services-container-line mt-14">
            <div className="relative services-container-line-point code "></div>
              <div className="relative services-container-line-point app bigger "></div>
              <div className="relative services-container-line-point ux "></div>
              <div className="relative services-container-line-point web3 "></div>
              <div className="relative services-container-line-point ai "></div>
              <div className="relative services-container-line-point loaning"></div>
            </div>
            <div className="relative flex flex-col items-start justify-start h-full services-container-info p-14">
              <div className="services-container-info-img">
                <img src={AppIcon} alt="startup" className="w-14" />
              </div>
              <div className="mt-6 text-left services-container-info-title">
                <h1 className="text-h3">{pt ? "Desenvolvimento" : "App"}</h1>
                <h1 className="text-h3">{pt ? "de aplicações" : "development"}</h1>
              </div>
              <div className="mt-6 text-left services-container-info-text">
              {pt ?
              <p>
                Desbloqueia todo o potencial do teu negócio com o nosso serviço personalizado de desenvolvimento de aplicações. <br /><br /> Desde o conceito até ao lançamento, fornecemos soluções completas que atendem às tuas necessidades exclusivas e superam as expectativas.  
              </p>
              :              
              <p>
                Unlock the full potential of your business with our customized application development service. <br /><br /> From concept to launch, we provide end-to-end solutions that meet your unique needs and exceed your expectations.  
              </p>}
              </div>
            </div>
          </div>
          <div className="relative hidden cinco:flex flex-col items-start justify-end w-1/2 h-full pb-40 services-container-right">
              <div className="mb-10 services-container-portofolio-title">
                <h1 className="relative text-left text-white text-h3">{pt ? "Portfolio" : "Portfólio"}</h1>
              </div>
              <div className="seis:max-w-[25rem] max-w-[18rem] overflow-x-auto my-scrollbar h relative flex">
                <div className="relative flex justify-start mb-2 services-container-portofolio-cards-container">
                  <div className="relative flex items-center justify-center mr-5 cursor-pointer services-container-portofolio-card spody hover:">
                    <img src={SpodyIcon} alt="alt" />
                  </div>
                </div>
              </div>
          </div>
          <div onClick={() => goToForm()} className="absolute service-contact-btn bottom-16 right-16"><span className="mr-2">{pt ? "interessado" : "interested"}</span><span>→</span></div>
        </div>
      </div>
    </section>
  );
}


export default App;