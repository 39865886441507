import React, { Component } from "react";
import "./software.css";

import Mockup1 from '../../../content/images/portfolio/software/mockup1.png';
import Mockup2 from '../../../content/images/portfolio/software/mockup2.png';
import Mockup3 from '../../../content/images/portfolio/software/mockup3.png';
import Laptop from '../../../content/images/portfolio/software/laptop.png';
import WorklabLogo from '../../../content/images/portfolio/software/worklab-logo.png';

function ItSkill({...props}) {
  let className = "absolute h-4 rounded-full it-skill-line bg-[#19D7E2] ";
  className += props.knowledge;
  return (
    <div className="relative flex justify-center items-center w-full mb-4">
      <h5 className="text-h5 mr-5 w-1/3 pl-5">{props.skill}</h5>
      <div id="skills-card-progress-bg" className="relative w-1/2 bg-[#00000080] h-4 rounded-full">
        <div className={className}></div>
      </div>
    </div>
  );
}

export class Software extends Component {
  render() {
    let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
    return (
      <section id="portfolio-software" className="relative min-h-[130vh] flex overflow-x-hidden">
        <h2 className="absolute w-full text-right text-h2 top-16 right-[8vw]">Software</h2>
        <div id="portfolio-software-left" className='reveal relative flex flex-col justify-center w-[60%] pl-[8vw] text-left text-h5'>
          <img src={WorklabLogo} alt="img" className="w-[15rem] mb-10"/>
          {pt ?
            <p>
              O WorkLab, a nossa plataforma principal de recrutamento e educação, é um testemunho da nossa habilidade em fornecer soluções excecionais que fazem a diferença. <br /><br /> Este é um exemplo brilhante do nosso compromisso com a excelência no desenvolvimento de software. Com um histórico comprovado na criação de plataformas impactantes, trazemos uma combinação de inovação e precisão para cada projeto.
            </p>
            :              
            <p>
              WorkLab, our flagship recruitment and educational platform, is a testament to our prowess in delivering exceptional solutions that make a difference. <br /><br /> This is one shining example of our commitment to excellence in software development. With a proven track record of creating impactful platforms, we bring a blend of innovation and precision to every project.
            </p>
          }   
          <span className="mt-8 text-p cursor-pointer reveal" onClick={() => window.open('https://worklabspace.com', '_blank')}>{pt ? "Abrir website" : "Open website"}</span>
        </div>
        <div id="portfolio-software-right" className='relative w-full flex items-center overflow-hidden'>
          <img src={Mockup1} alt="img" className='software-mockups a reveal absolute bottom-[8rem] w-[60vw] right-[-10vw]'/>
          <img src={Mockup2} alt="img" className='software-mockups b reveal absolute bottom-[8rem] w-[60vw] right-[-10vw]'/>
          <img src={Mockup3} alt="img" className='software-mockups c reveal absolute bottom-[8rem] w-[60vw] right-[-10vw]'/>
          <img src={Laptop} alt="img" id="software-laptop" className='absolute reveal bottom-[8rem] w-[60vw] right-[-10vw]'/>
          <div id="software-card-skills" className="absolute reveal py-5 px-4 bottom-[5rem] left-[7vw]">
            <h2 className="text-h5 text-left mb-5">Skills</h2>
            <div className="relative flex flex-col w-full text-left">
              <ItSkill skill="Web" knowledge="w-4/5"/>
              <ItSkill skill="UX/UI" knowledge="w-2/5"/>
              <ItSkill skill="Software" knowledge="w-full"/>
              <ItSkill skill="Cyber" knowledge="w-3/5"/>
              <ItSkill skill="Hardware" knowledge="w-1/5"/>
              <ItSkill skill="App" knowledge="w-4/5"/>
              <ItSkill skill="Networks" knowledge="w-3/5"/>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Software;
