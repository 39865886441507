import { Routes, Route } from 'react-router-dom';
import { Home, Services, Budget, Form, Contact, Error, Portfolio, Apply, ApplyForm, Links, Blog, BlogPage, NewPartner, Partners, PartnerPage, Privacy, PartnersPrivacy, PartnerLinks, About } from './containers/index';

function AppRoutes() {
    return (
        <Routes>
            <Route index element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/budget" element={<Budget />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/form" element={<Form />} />
            <Route path="/apply" element={<Apply />} />
            <Route path="/new-partner" element={<NewPartner />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/partner" element={<PartnerPage />} />
            <Route path="/about" element={<About />} />
            <Route path="/apply-form" element={<ApplyForm />} />
            <Route path="/links" element={<Links />} />
            <Route path="/rafaelcoias" element={<PartnerLinks partner={"rafaelcoias"} />} />
            <Route path="/tomasrocha" element={<PartnerLinks partner={"tomasrocha"} />} />
            <Route path="/duartebarreiros" element={<PartnerLinks partner={"duartebarreiros"} />} />
            <Route path="/duartesousa" element={<PartnerLinks partner={"duartesousa"} />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/article/:id" element={<BlogPage />} />
            <Route path="/post" element={<BlogPage />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/partner-terms" element={<PartnersPrivacy />} />X
            <Route path="*" element={<Error />} />
        </Routes>
    );
}

export default AppRoutes;