import React from 'react';

interface SectionProps {
  title: string;
  children: React.ReactNode;
}

export function Section({ title, children }: SectionProps) {
  return (
    <section className="mb-12 text-left text-[1.5rem] reveal w-full">
      <h2 className="mb-4 text-[1.5rem] cinco:text-[2rem] font-GilroyBold ">{title}</h2>
      <div className="space-y-4 text-gray-700">{children}</div>
    </section>
  );
}