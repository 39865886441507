import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase.js";

export default function ArticlePage() {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [article, setArticle] = useState<any>(null);
  const pt = localStorage.getItem("lang") === "pt";

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const docRef = doc(db, "blog", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setArticle(docSnap.data());
        }
      } catch (error) {
        console.error("Error getting document: ", error);
      }
      setLoading(false);
    }
    if (id) fetchData();
    else setLoading(false);
  }, [id]);

  const processArticleContent = (content: string) => {
    const div = document.createElement("div");
    div.innerHTML = content;

    // Find all paragraphs
    Array.from(div.querySelectorAll("p")).forEach((pElement) => {
      if (pElement.innerHTML.includes("&lt;hr/&gt;")) {
        const hr = document.createElement("hr");
        hr.classList.add("my-6");
        pElement.innerHTML = pElement.innerHTML.replace("&lt;hr/&gt;", ""); // Remove the placeholder
        pElement.replaceWith(hr); // Replace the <p> element with the <hr>
      }
    });

    return div.innerHTML;
  };
  if (loading) {
    return (
      <div className="relative flex items-center justify-center w-full min-h-screen">
        <CircularProgress color="inherit" />
      </div>
    );
  }

  if (!article) {
    return (
      <div className="relative flex items-center justify-center w-full min-h-screen">
        <p>Artigo não encontrado.</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center w-full min-h-screen gap-6 pt-[6rem] pb-10 text-white blog-bg">
      <div className="w-[90%] cinco:w-[70%] flex flex-col gap-6 text-left">
        <div className="w-full">
          <button onClick={() => navigate(-1)}>← {pt ? "Voltar" : "Back"}</button>
        </div>
        <h1 className="text-[1.2rem] cinco:text-[2rem] font-GilroyBold text-balance text-[var(--primary)]">
          {article.title}
        </h1>
        <h2 className="cinco:text-[1.5rem] font-bold">{article.description}</h2>
        <div>
          <p>
            {pt ? "De:" : "By:"}{" "}
            <span className="font-bold">{article.author}</span>
          </p>
          <p>
            {pt ? "Publicado no dia:" : "Publish date:"}{" "}
            <span className="font-bold">{article.publishDate}</span>
          </p>
        </div>
        <img
          src={article.image}
          alt="imagem do artigo"
          className="h-[20rem] object-cover rounded-[10px]"
        />
      </div>
      <div
        className="article-content relative py-[2rem] w-[90%] cinco:w-[70%] flex flex-col items-center min-h-[15rem] gap-6 !text-left"
        dangerouslySetInnerHTML={{
          __html: processArticleContent(article.article),
        }} // Processed HTML
      ></div>
    </div>
  );
}
