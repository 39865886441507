export const technologies = {
  webDevelopment: {
    title: "Web Development",
    techs: [
      {
        name: "React",
        imageUrl:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
      },
      {
        name: "TypeScript",
        imageUrl:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg",
      },
      {
        name: "Tailwind",
        imageUrl:
          "https://www.vectorlogo.zone/logos/tailwindcss/tailwindcss-icon.svg",
      },
      {
        name: "Next.js",
        imageUrl:
          "https://assets.vercel.com/image/upload/v1588805858/repositories/vercel/logo.png",
      },
      {
        name: "Node.js",
        imageUrl:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg",
      },
      { name: "NestJS", imageUrl: "https://nestjs.com/img/logo-small.svg" },
      {
        name: "HTML",
        imageUrl:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg",
      },
      {
        name: "CSS",
        imageUrl:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg",
      },
      {
        name: "JavaScript",
        imageUrl:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg",
      },
    ],
  },
  mobileDevelopment: {
    title: "Mobile Development",
    techs: [
      {
        name: "React Native",
        imageUrl:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
      },
      {
        name: "Swift",
        imageUrl:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/swift/swift-original.svg",
      },
      {
        name: "Flutter",
        imageUrl:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/flutter/flutter-original.svg",
      },
      {
        name: "Node.js",
        imageUrl:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg",
      },
      { name: "NestJS", imageUrl: "https://nestjs.com/img/logo-small.svg" },
      {
        name: "TypeScript",
        imageUrl:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg",
      },
    ],
  },
  databases: {
    title: "Databases",
    techs: [
      {
        name: "Firebase",
        imageUrl:
          "https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg",
      },
      {
        name: "SQL",
        imageUrl:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original.svg",
      },
      {
        name: "MongoDB",
        imageUrl:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original.svg",
      },
      {
        name: "PostgreSQL",
        imageUrl:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/postgresql/postgresql-original.svg",
      },
    ],
  },
  design: {
    title: "Design",
    techs: [
      {
        name: "Figma",
        imageUrl: "https://www.vectorlogo.zone/logos/figma/figma-icon.svg",
      },
      {
        name: "Adobe XD",
        imageUrl:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/Adobe_XD_CC_icon.svg/2101px-Adobe_XD_CC_icon.svg.png",
      },
      {
        name: "Canva",
        imageUrl: "https://www.vectorlogo.zone/logos/canva/canva-icon.svg",
      },
    ],
  },
  services: {
    title: "Cloud & Services",
    techs: [
      {
        name: "AWS",
        imageUrl:
          "https://images.seeklogo.com/logo-png/31/1/amazon-web-services-aws-logo-png_seeklogo-319188.png?v=638687094010000000",
      },
      {
        name: "Azure",
        imageUrl:
          "https://www.vectorlogo.zone/logos/microsoft_azure/microsoft_azure-icon.svg",
      },
      {
        name: "Google Cloud",
        imageUrl:
          "https://www.vectorlogo.zone/logos/google_cloud/google_cloud-icon.svg",
      },
      {
        name: "GitHub",
        imageUrl: "https://www.vectorlogo.zone/logos/github/github-icon.svg",
      },
      {
        name: "Vercel",
        imageUrl:
          "https://assets.vercel.com/image/upload/v1588805858/repositories/vercel/logo.png",
      },
      { name: "Railway", imageUrl: "https://railway.app/brand/logo-light.svg" },
    ],
  },
};
