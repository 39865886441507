import React from "react";

import Video from "../../../content/images/home/home-about-launch.png";

function launch() {

  let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

  return (
    <div id="home-launch-all" className="flex items-center justify-center w-3/5 bg-center bg-no-repeat bg-cover home-section1-sections">
      <img src={Video} alt="img" className="absolute w-full h-full home-section1-videos"></img>
      <div className="fade-black-img absolute h-full w-full rounded-[25px]"></div>
      <div id="home-launch-container" className="relative flex home-section1-sections-title">
        <h1 className="relative text-h4 cinco:text-h3 sete:text-h2">{pt ? "Lançamos o novo presente" : "We launch the new present"}</h1>
      </div>
    </div>
  );
}

export default launch;
