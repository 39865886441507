import React, { Component } from 'react';

import logoQuatroP from "../../content/images/icons-logos/quatrop.png";
import LogoSogimonte from "../../content/images/icons-logos/logo-sogimonte.png";
import LogoSuspeitos from "../../content/images/icons-logos/logo-Suspeitos.png";
import LogoAgri from "../../content/images/icons-logos/logo-Agri_group.png";

export class parcerias extends Component {
  render() {
    return (
      <div id="parcerias-all" className='relative z-40 flex flex-col items-center justify-start h-20 my-20 text-left'>
        <div id="parcerias-marquee" className='relative w-full'>
          <ul id="parcerias-container" className='relative flex items-center justify-around bg-black'>
            <li className='parcerias-logo'>
              <img src={logoQuatroP} alt="deloitte" className='parcerias-imgs' />
            </li>
            <li className='parcerias-logo'>
              <img src={LogoSogimonte} alt="deloitte" className='parcerias-imgs' />
            </li>
            <li className='parcerias-logo'>
              <img src={LogoSuspeitos} alt="deloitte" className='parcerias-imgs' />
            </li>
            <li className='parcerias-logo'>
              <img src={LogoAgri} alt="deloitte" className='parcerias-imgs' />
            </li>


            <li className='parcerias-logo'>
              <img src={logoQuatroP} alt="deloitte" className='parcerias-imgs' />
            </li>
            <li className='parcerias-logo'>
              <img src={LogoSogimonte} alt="deloitte" className='parcerias-imgs' />
            </li>
            <li className='parcerias-logo'>
              <img src={LogoSuspeitos} alt="deloitte" className='parcerias-imgs' />
            </li>
            <li className='parcerias-logo'>
              <img src={LogoAgri} alt="deloitte" className='parcerias-imgs' />
            </li>


            <li className='parcerias-logo'>
              <img src={logoQuatroP} alt="deloitte" className='parcerias-imgs' />
            </li>
            <li className='parcerias-logo'>
              <img src={LogoSogimonte} alt="deloitte" className='parcerias-imgs' />
            </li>
            <li className='parcerias-logo'>
              <img src={LogoSuspeitos} alt="deloitte" className='parcerias-imgs' />
            </li>
            <li className='parcerias-logo'>
              <img src={LogoAgri} alt="deloitte" className='parcerias-imgs' />
            </li>


            <li className='parcerias-logo'>
              <img src={logoQuatroP} alt="deloitte" className='parcerias-imgs' />
            </li>
            <li className='parcerias-logo'>
              <img src={LogoSogimonte} alt="deloitte" className='parcerias-imgs' />
            </li>
            <li className='parcerias-logo'>
              <img src={LogoSuspeitos} alt="deloitte" className='parcerias-imgs' />
            </li>
            <li className='parcerias-logo'>
              <img src={LogoAgri} alt="deloitte" className='parcerias-imgs' />
            </li>


          </ul>
        </div>
      </div>
    )
  }
}

export default parcerias;
