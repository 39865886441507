import React, { Component } from "react";
import "./header.css";


export class header extends Component {
  render() {
    let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
    return (
      <article id="home-header" className="relative flex flex-col justify-center items-start px-[20vw] h-screen bg-homeHeader bg-cover">
        <div id="home-header-main" className="relative flex flex-col font-GilroyBold">
          <div className="relative flex">
            <p id="home-header-title" className="relative text-h1">Trust your digital</p>
          </div>
          <div id="home-header-title-slogan" className="relative flex flex-col justify-start items-start text-h1 overflow-hidden h-[4.5rem] mb-16">
            <div className="relative flex flex-col w-full h-full">
              <p id="home-header-title-2" className="relative text-left">vision</p>
              <p id="home-header-title-3" className="relative text-left">dream</p>
              <p id="home-header-title-4" className="relative text-left">flow</p>
            </div>
          </div>
        </div>
        <main id="home-header-description" className="relative w-full text-left -p text-h5">
          { pt ? <h1 className="text-left">Agência de desenvolvimento que <br /> cria a nova era de sucesso digital</h1> : <h1 className="text-left">Developers Agency that creates <br /> the new era of digital success</h1>}
        </main>
        <div className="relative flex justify-start mt-6 text-h5 w-60">
          <button id="home-header-button" onClick={() => window.location.replace('/budget')}>{pt ? "Orçamento" : "Budget"}</button>
        </div>
      </article>
    );
  }
}

export default header;
