import React from 'react';

interface TeamMemberProps {
  name: string;
  role: string;
  images: string[];
  bio: string;
  link: string;
}

export function TeamMember({ name, role, images, bio, link }: TeamMemberProps) {
  let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

  return (
    <div className="self-center overflow-hidden rounded-lg shadow-2xl justify-self-center place-self-center group">
      <div className="relative overflow-hidden aspect-square">
        <img
          src={images[0]}
          alt={name}
          className="object-cover w-full h-full transition-all duration-300 group-hover:opacity-0"
        />
        <img
          src={images[1]}
          alt={name}
          className="absolute top-0 left-0 object-cover w-full h-full transition-all duration-300 opacity-0 group-hover:opacity-100"
        />
      </div>
      <div className="p-6 h-[18rem] flex flex-col justify-between">
        <div>
          <h3 className="text-[1.2rem] font-bold">{name}</h3>
          <p className="mb-3 font-medium text-blue-600">{role}</p>
        </div>
        <div>
          <p className="mb-4 text-sm text-gray-600">{bio}</p>
          <div className="flex justify-center">
            <a href={`/${link}`} className='hover:underline my-text'>{pt ? "Ver mais" : "Learn more"}</a>
          </div>
        </div>
      </div>
    </div>
  );
}