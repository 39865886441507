import React from 'react';

import Logo from '../../content/images/icons-logos/logo-d.png';
import logogithub from "../../content/images/icons-logos/logo-github.png";
import logoinstagram from "../../content/images/icons-logos/logo-instagram.png";
import logolinkedin from "../../content/images/icons-logos/logo-linkedin.png";

export default function Links() {
    return (
        <div className='px-[8vw] h-screen bg-homeHeader bg-no-repeat bg-cover flex justify-center pt-[6rem] bg-center'>
            <div className='absolute top-0 left-0 w-full h-full bg-[rgba(0,0,0,.1)]'></div>
            <div className='flex flex-col w-full cinco:w-[35rem] font-bold gap-[2rem]'>
                <div className='flex flex-col items-center'>
                    <div className='rounded-full w-[6rem] h-[6rem] bg-black flex justify-center items-center mb-4'>
                        <img src={Logo} alt="img" className='w-[4rem] relative left-[.2rem]' />
                    </div>
                    <p className='text-[1.6rem]'>Vision D</p>
                    <a className='hover:underline' href="mailto:geral@visiond.pt">geral@visiond.pt</a>
                </div>
                <div className='flex flex-col w-full gap-4'>
                    <div onClick={() => window.open("https://visiond.pt", '_blank')} className='relative bg-[#231f20] rounded-full h-[3.5rem] w-full flex justify-center items-center cursor-pointer hover:bg-[transparent] transition-all duration-300 ease border-2 border-[#231f20]'>
                        <p>Website</p>
                    </div>
                    <div onClick={() => window.open("https://startups.visiond.pt/", '_blank')} className='relative bg-[#231f20] rounded-full h-[3.5rem] w-full flex justify-center items-center cursor-pointer hover:bg-[transparent] transition-all duration-300 ease border-2 border-[#231f20]'>
                        <p>Startups</p>
                    </div>
                    <div onClick={() => window.open("https://worklabspace.com/", '_blank')} className='relative bg-[#231f20] rounded-full h-[3.5rem] w-full flex justify-center items-center cursor-pointer hover:bg-[transparent] transition-all duration-300 ease border-2 border-[#231f20]'>
                        <p>Worklab</p>
                    </div>
                    {/* <div onClick={() => window.open("https://outlook.office365.com/owa/calendar/StartupsSessionsFundingatWebsummit@visiond.pt/bookings/", '_blank')} className='relative bg-[#231f20] rounded-full h-[3.5rem] w-full flex justify-center items-center cursor-pointer hover:bg-[transparent] transition-all duration-300 ease border-2 border-[#231f20]'>
                        <p>Schedule</p>
                    </div> */}
                </div>
                <div className='flex justify-center w-full gap-8'>
                    <img src={logoinstagram} onClick={() => window.location.replace("https://instagram.com/visiondpt")} alt="Logo vision" className="w-10 h-10 footer-social-media brightness-0 invert" />
                    <img src={logolinkedin} onClick={() => window.location.replace("https://www.linkedin.com/company/vision-d-developers/")} alt="Logo vision" className="w-10 h-10 footer-social-media brightness-0 invert" />
                    <img src={logogithub} onClick={() => window.location.replace("https://github.com/visiond-community")} alt="Logo vision" className="w-10 h-10 footer-social-media brightness-0 invert" />
                </div>
            </div>
        </div>
    );
}

