import React from 'react';
import TechStack from './techStack';
import { technologies } from './techsList';

export default function Techs() {
    let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
    return (
        <div id="about-techs" className='px-[6vw] flex flex-col gap-6'>
            <h1 className='font-GilroyBold text-[2rem] reveal'>{pt ? "As Nossas Tecnologias" : "Our Technologies"}</h1>
            <TechStack title={technologies.webDevelopment.title} technologies={technologies.webDevelopment.techs} />
            <TechStack title={technologies.mobileDevelopment.title} technologies={technologies.mobileDevelopment.techs} />
            <TechStack title={technologies.databases.title} technologies={technologies.databases.techs} />
            <TechStack title={technologies.design.title} technologies={technologies.design.techs} />
            <TechStack title={technologies.services.title} technologies={technologies.services.techs} />
        </div>
    );
}

