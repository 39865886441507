import {Header, SectionAbout, SectionServices, SectionDashboard, Parcerias, SectionAreas} from './index'; 
import { Helmet } from "react-helmet-async";
import "./home.css"
import Video from "../../content/images/home/video.mp4"
import Video2 from "../../content/images/home/video2.mp4"
function Home() {
  return (
    <div className='relative'>
      <Helmet>
        <title>Vision D</title>
        <meta name="description" content="Developers agency that creates the new era of digital success" />
        <link rel='canonical' href='/' />
      </Helmet>
      <Header/>
      <div id="home-body">
        <SectionAreas/>
        <Parcerias/>
        <SectionAbout/>
        <SectionDashboard/>
        <SectionServices/>
      </div>
    </div>

  );
}

export default Home;
