import React from 'react';

export default function Header() {
    let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

    return (
        <div className='relative flex flex-col items-center justify-center min-h-screen'>
            <div className='absolute top-0 left-0 w-full h-full bg-strategyHeader bg-[50%_70%] cinco:bg-center z-[-1]'></div>
            {pt ?
                <p className='relative text-h3 cinco:text-h2 text-balance'>Sobre a <span className='font-GilroyBold'>Vision D</span></p>
                :
                <p className='relative text-h3 cinco:text-h2 text-balance'>About <span className='font-GilroyBold'>Vision D</span></p>
            }
            <div className='flex flex-col gap-2 mt-6 seis:flex-row'>
                <button onClick={() => window.location.replace("/about#about-teams")} className='relative w-[8rem] transition-all border hover:bg-[rgba(0,0,0,.5)] py-2 rounded-full'>{pt ? "Equipa" : "Team"}</button>
                <button onClick={() => window.location.replace("/about#about-history")} className='relative w-[8rem] transition-all border hover:bg-[rgba(0,0,0,.5)] py-2 rounded-full'>{pt ? "História" : "History"}</button>
                <button onClick={() => window.location.replace("/about#about-techs")} className='relative w-[8rem] transition-all border hover:bg-[rgba(0,0,0,.5)] py-2 rounded-full'>{pt ? "Tecnologias" : "Technologies"}</button>
            </div>
        </div>
    );
}

