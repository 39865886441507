import React from "react";

import Logo from "../../content/images/icons-logos/logo-d.png";
import logogithub from "../../content/images/icons-logos/logo-github.png";
import logoinstagram from "../../content/images/icons-logos/logo-instagram.png";
import logolinkedin from "../../content/images/icons-logos/logo-linkedin.png";
import PartnerInfo from "./partnerInfo.json";
import { TbWorldShare } from "react-icons/tb";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export default function PartnerLinks({ partner }: { partner: string }) {
  const navigate = useNavigate();

  const info = PartnerInfo[partner];
  let pt =
    localStorage.getItem("lang") !== null &&
      localStorage.getItem("lang") === "pt"
      ? true
      : false;

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = `/partners/vcards/${partner}.vcf`;
    link.download = "contact.vcf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="px-[8vw] h-screen bg-homeHeader bg-no-repeat bg-cover flex justify-center pt-[7rem] bg-center">
      <div className="absolute top-0 left-0 w-full h-full bg-[rgba(0,0,0,.2)] z-[0]"></div>
      <div className="flex flex-col w-full cinco:w-[35rem] font-bold gap-[2rem] z-[1]">
        <div className="flex flex-col items-center">
          <div className="relative rounded-full w-[6rem] h-[6rem] overflow-hidden bg-black flex justify-center items-center mb-4 group">
            {info?.photos &&
              <>
                <img
                  src={info.photos[0]}
                  alt="img"
                  className="object-cover w-full h-full transition-all duration-300 group-hover:opacity-0"
                />
                <img
                  src={info.photos[1]}
                  alt="img"
                  className="absolute top-0 left-0 object-cover w-full h-full transition-all duration-300 opacity-0 group-hover:opacity-100"
                />
              </>
            }
          </div>
          <p className="text-[1.6rem]">{info.name}</p>
          <div className="flex items-center gap-2">
            <MdEmail />
            <a className="hover:underline" href={`mailto:${info.email}`}>
              {info.email}
            </a>
          </div>
        </div>
        <div className="flex flex-col w-full gap-4">
          <button
            onClick={() => navigate("/")}
            className="relative my-border bg-[#231f20] rounded-full h-[3.5rem] w-full flex gap-2 justify-center items-center cursor-pointer hover:bg-[transparent] transition-all duration-300 ease border-2 border-[#231f20]"
          >
            <div className="card flex gap-2 justify-center items-center w-full h-full overflow-hidden !py-0 !rounded-[8px] !shadow-none group-hover:brightness-90 transition-all">
              <p>Vision D - Website</p>
            </div>
          </button>
          {info.url && (
            <button
              onClick={() => window.open(info.url, "_blank")}
              className="relative my-border bg-[#231f20] rounded-full h-[3.5rem] w-full flex gap-2 justify-center items-center cursor-pointer hover:bg-[transparent] transition-all duration-300 ease border-2 border-[#231f20]"
            >
              <div className="card flex gap-2 justify-center items-center w-full h-full overflow-hidden !py-0 !rounded-[8px] !shadow-none group-hover:brightness-90 transition-all">
                {/* <TbWorldShare size={24} /> */}
                <p>{!pt ? "Personal Website" : "Website Pessoal"}</p>
              </div>
            </button>
          )}
          <button
            className="relative my-border bg-[#231f20] rounded-full h-[3.5rem] w-full flex gap-2 justify-center items-center cursor-pointer hover:bg-[transparent] transition-all duration-300 ease border-2 border-[#231f20]"
            onClick={handleDownload}
          >
            <div className="card flex gap-2 justify-center items-center w-full h-full overflow-hidden !py-0 !rounded-[8px] !shadow-none group-hover:brightness-90 transition-all">
              {/* <FaPhone  size={20} /> */}

              <p className="font-bold">
                {!pt ? "Save Contact" : "Guardar Contacto"}
              </p>
            </div>
          </button>
          {info.linkedin && (
            <button
              onClick={() => window.open(info.linkedin, "_blank")}
              className="relative my-border bg-[#231f20] rounded-full h-[3.5rem] w-full flex gap-2 justify-center items-center cursor-pointer hover:bg-[transparent] transition-all duration-300 ease border-2 border-[#231f20]"
            >
              <div className="card flex gap-2 justify-center items-center w-full h-full overflow-hidden !py-0 !rounded-[8px] !shadow-none group-hover:brightness-90 transition-all">
                {/* <img
                  src={logolinkedin}
                  onClick={() =>
                    window.location.replace(
                      info.linkedin
                    )
                  }
                  alt="Logo vision"
                  className="w-6 h-6 footer-social-media brightness-0 invert"
                /> */}
                <p>LinkedIn</p>
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
