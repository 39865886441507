import React from "react";

import SpodyIcon from "../../../content/images/icons-logos/icon-spody.png";
import StartupsIcon from "../../../content/images/icons-logos/logo-loaning.png";

function reveal() {
  var reveals = document.querySelectorAll('.reveal');

  for (var i = 0; i < reveals.length; i++){
      var windowHeight = window.innerHeight;
      var revealTop = reveals[i].getBoundingClientRect().top;
      var revealPoint = 150;

      if (revealTop < windowHeight - revealPoint) {
          reveals[i].classList.add('active');
      }
      else {
          reveals[i].classList.remove('active');
      }
  }  
}

function goToForm() {
  window.location.replace('https://startups.visiond.pt');
}

window.addEventListener('scroll', reveal);

function Startups() {
  let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;
  return (
    <section id="services-startups" className="relative flex h-screen bg-center bg-no-repeat bg-cover service-all bg-servicesStartup">
      <div className="absolute w-full h-full fade-black-img"></div>
      <div className="relative w-full h-full reveal">
        <div className="relative flex w-full h-full px-24 py-24 services-container">
          <div className="relative flex items-center justify-start w-1/2 h-full services-container-left">
            <div className="relative flex flex-col justify-around h-full services-container-line mt-14">
              <div className="relative services-container-line-point code "></div>
              <div className="relative services-container-line-point app "></div>
              <div className="relative services-container-line-point ux "></div>
              <div className="relative services-container-line-point web3 "></div>
              <div className="relative services-container-line-point ai "></div>
              <div className="relative services-container-line-point loaning bigger"></div>
            </div>
            <div className="relative flex flex-col items-start justify-start h-full services-container-info p-14">
              <div className="services-container-info-img">
                <img src={StartupsIcon} alt="startup" className="w-16"/>
              </div>
              <div className="mt-6 text-left services-container-info-title">
                <h1 className="text-h3">{pt ? "Lançamento" : "Startups"}</h1>
                <h1 className="text-h3">{pt ? "de startups" : "launch"}</h1>
              </div>
              <div className="mt-6 text-left services-container-info-text">
              {pt ?
              <p>
                Descubre as soluções tecnológicas perfeitas para teus projetos com os nossos serviços especializados de consultoria. <br /><br /> Desde a seleção das melhores tecnologias até o desenvolvimento de projetos, outsourcing e lançamento de projetos, oferecemos suporte abrangente em todas as etapas. <br /> Junta-te a nós para melhorar os teus processos, aumentar a eficiência e garantir resultados de projetos bem-sucedidos.
              </p>
              :              
              <p>
                Discover the perfect technology solutions for your projects with our expert IT Startups services. <br /><br /> From selecting the best technologies to project development, outsourcing, and project launch, we provide comprehensive support at every stage. <br /> Partner with us to streamline your processes, enhance efficiency, and ensure successful project outcomes.                
              </p>}
              </div>
            </div>
          </div>
          <div className="relative hidden cinco:flex flex-col items-start justify-end w-1/2 h-full pb-40 services-container-right">
              <div className="mb-10 services-container-portofolio-title">
                <h1 className="relative text-left text-white text-h3">{pt ? "Histórias de sucesso" : "Success stories"}</h1>
              </div>
              <div className="seis:max-w-[25rem] max-w-[18rem] overflow-x-auto my-scrollbar h relative flex">
                <div className="relative flex justify-start mb-2 services-container-portofolio-cards-container">
                  <div className="relative flex items-center justify-center mr-5 cursor-pointer services-container-portofolio-card spody hover:">
                    <img src={SpodyIcon} alt="alt" />
                  </div>
                  <div onClick={() => {}} className="relative flex items-center justify-center mr-5 bg-center bg-cover cursor-pointer services-container-portofolio-card bg-worklab hover:">
                  </div>
                </div>
              </div>
          </div>
          <div onClick={() => goToForm()} className="absolute service-contact-btn bottom-16 right-16"><span className="mr-2">{pt ? "interessado" : "interested"}</span><span>→</span></div>
        </div>
      </div>
    </section>
  );
}

export default Startups;