import React from "react";

function create() {
  
  let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;


  return (
    <div id="home-create-all" className="flex items-center justify-center w-3/5 bg-center bg-no-repeat bg-cover home-section1-sections section-content fade-in bg-homeAboutCreate">
      <div id="home-create-container" className="relative flex home-section1-sections-title">
        <h1 className="text-h3 sete:text-h2">{pt ? "Imaginamos o futuro" : "We image the future"}</h1>
      </div>
    </div>
  );
}

export default create;
